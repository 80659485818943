"use client";
import React from "react";
import Snowfall from "react-snowfall";

const SnowfallEffect = ({ open }: { open: boolean }) => {
  if (open)
    return (
      <Snowfall
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          minHeight: "100%",
        }}
        snowflakeCount={40}
      />
    );
};

export default SnowfallEffect;
